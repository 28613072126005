// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyD6spSb5g6qd_De0ACHVUmdf-tV5iZZxRQ",
    authDomain: "darkflex-649e8.firebaseapp.com",
    projectId: "darkflex-649e8",
    storageBucket: "darkflex-649e8.appspot.com",
    messagingSenderId: "833922849005",
    appId: "1:833922849005:web:f2f35e8d47e870a00cd4e4",
    measurementId: "G-HYK4D2CG7R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();
const getClientToken = () => {
    getToken(messaging, { vapidKey: 'BE60lSrCUYNP41VmaUEhKL2-M0gVN8_6bi6YeZR5uiIGTrLPn94EBsSkBwAbNbaW0faLM7J' }).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            console.log(currentToken)
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    });
}

export { getClientToken }

