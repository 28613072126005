//TrainingCalendar -> this

function Selector(
    {
        handleOnClickOne, // клик на первый чек бокс
        nameOne, // название первого чекбокса
        handleOnClickTwo, // клик на второй чек бокс
        styleSelector, // стиль
        switchControl, // внешний контроль отображения, true -вкл свитчер
        onlyOn, // true - не возвращать селектор при повторном клике, можно только включать
        styleCheckboxButton, // стиль свитчера
        styleText, // стиль текста
    }

) {

    function onClickOne() {
        if (onlyOn !== true) {
            if (switchControl) {
                handleOnClickOne(false);
            } else { 
                handleOnClickOne(true);
            }
        } else {
            handleOnClickOne(true);
        }
    }

    return (
        // КНОПКИ УПРАВЛЕНИЯ ОТОБРАЖЕНИЕМ НА СТРАНИЦЕ 
        <>
            <div className={`formadd__checkbox ${styleSelector}`}>
                <button className={`formadd__checkbox-button formadd__checkbox-button_flex ${styleCheckboxButton} ${switchControl ? 'formadd__checkbox-button_on ' : ''}`}
                    onClick={onClickOne} />
                <p className={`formadd__checkbox-button-name ${styleText}`}>{nameOne}</p>
            </div>
        </>
    )
}

export default Selector;
