import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ClientInfo from "../ClientInfo/ClientInfo";
import TimeTable from "../TimeTable/TimeTable"; // импорт списка тренировок
import InfoTab from "../InfoTab/InfoTab"; // выезжающее меню инфо
import FormSignIn from "../FormSignIn/FormSignIn"; // форма авторизации
import QRCode from "../QRCode/QRCode";
import InfoTooltip from "../InfoTooltip/InfoTooltip"; // инфотул
import LoadingPage from '../LoadingPage/LoadingPage'; //Страница загрузки во время авторизации
import SignIn from "../SignIn/SignIn"; // страница для авторизации
import { getClientToken } from "./../../utils/FMCPush";


function PageStudio({
    api, // передать АПИ студии
    apiAuthClient, // Апи авторизации клиента
    backgroundColor,// цвет фона
    timetableWeekButtonStyle, // цвет фона кнопок дней недели
    title,
    subTitle,
    textMainTitleColor, // цвет Названия страницы
    textTitleColor, // цвет титульников
    titleTimeTable,
    setChangeVisit,
    isShowAllAbonements, // показывать инфу о всех абонементах или только о заведенных в базу ( true - всех, false - заведенных в базу)
    infoFlex, // давать ли информацию по абонементам Flex
    infoDark, // давать ли информацию по абонементам Dark
    imgClientTrain, // картинка кнопки "Мои тренировки"
    imageWA, // картинка кнопки связи
    imgSignIn, // картинка авторизации
    imgMainPage,// картинка кнопки "На главную"
    imgInfoTab, // картинка инфа 
    imgQrCode,// картинка qrcode
    phoneWhatsApp, // сотовый студии
    timetableButtonStyle, // цвет фона кнопок переключения недели
    styleText, // стиль текста
    styleFooter, // стиль футера ( используется для Dark)
    backgoundColorForm,
    handleIsAuthClient,
    link,
    textButton,
    childrenSubscriptionInformation,
    styleButton, // стиль кнопок Больших (навигация на странице)
    //statePage, // переключать состояние на общие и личные записи на тренировку(зависит добавить или отменить щапись на тренировку)
    //abonement // пробросить данные абонемента из страницы
}) {

    //Авторизация и вход
    const [isAuthClient, setIsAuthClient] = useState(false); // проверить авторизован ли пользователь для защиты путей и отображения кнопок в хедере
    const [isOpenFormSignIn, setIsOpenFormSignIn] = useState(false); // управлять отображением формы авторизации
    const [isPreloaderAuthWaiting, setIsPreloaderAuthWaiting] = useState(true); // true - показывать загрузочную страницу, пока проверяется автоиизация
    //данные
    const [idClient, setIdClient] = useState(''); // id Клиента
    const [clientData, setClientData] = useState({}); // объект данных о клиенте
    const [trains, setTrains] = useState([]) // массив всех тренировок
    const [groupeTrains, setGroupeTrains] = useState([]); // массив только групповых тренировок
    const [abonementClientFlexGroupe, setAbonementClientFlexGroupe] = useState({}); // информация об абонементе
    const [abonementClientFlexPersonal, setAbonementClientFlexPersonal] = useState({}); // информация об абонемент
    const [abonementClientDarkGroupe, setAbonementClientDarkGroupe] = useState({}); // информация об абонементе
    const [abonementClientDarkPersonal, setAbonementClientDarkPersonal] = useState({}); // информация об абонементе
    const [listOfVisits, setListOfVisits] = useState([]); // общий список посещений тренировок( групповые и персональные)
    const [visitedTrainsToClient, setVisitedTrainsToClients] = useState([]); // посещеные тренировки клиентом
    const [nameClient, setNameClient] = useState(''); // имя клиента
    const [surnameClient, setSurnameClient] = useState(''); // фамилия
    const [isChangeInformation, setIsChangeInformation] = useState(false); // мгновенная отрисовка изменений
    const [isLoaderTraining, setIsLoaderTraining] = useState(false); // отображать лоадер при загрузке всех тренировок
    const [isLoaderActiveTrainingActive, setIsLoaderActiveTraining] = useState(false); // лоадер активных тренировок
    const [isLoaderHistoryVisitsTraining, setIsLoaderHistoryVisitsTraining] = useState(false); // лоадер истории посещений
    const [isClientInformationLoader, setIsClientInformationLoader] = useState(false); // лоадер информации о клиенте
    //управление
    const [client, setClient] = useState(true); // управлять отображением, false - клиент отсутсвует в базе, true - есть клиент в базе
    const [isClickOnTimeTable, setClickOnTimeTable] = useState(true); // кликнуто ли на расписание
    const [isClickOnActiveTimeTable, setClickOnActiveTimeTable] = useState(false); // кликнуто ли на активных записях на тренировку
    //Инфотаб
    const [isOpenInfotab, setIsOpenInfotab] = useState(false); // отображать информационный блок или нет
    //Инфотул
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
    const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    //qrcode 
    const [isOpenQrCode, setIsOpenQrCode] = useState(false); // true открыть qrcode

    //Тренировки 
    function handleSetTrains(trains) { // передать массив тренировок для отрисовки
        setTrains(trains)
    }


    useEffect(() => { // получить текущую дату и загрузить актывные записи на нее для первоначального отображения
        if (isAuthClient) {

            let date = new Date();
            api.getTrainsOfClientsVisit(idClient) // аполучить все тренировки, на которые записан клиент
                .then((trains) => {
                    setTrains(trains.trains);
                }).catch((err) => {
                    console.log(err);
                });
        }
    }, [isAuthClient])

    useEffect(() => { // получить текущую дату и загрузить общее расписание на нее для отображения
        let date = new Date();
        api.getTrainsOfDate(date)
            .then((trains) => {
                let listTrains = trains.trains.sort((a, b) => new Date(a.dateOn) - new Date(b.dateOn))
                setTrains(listTrains)
            }).catch((err) => {
                // console.log(err);
            });
    }, [])

    // Управление изменением информацией на странице и отрисовки без перезагрузки 
    function handleChangeInformation() {
        setIsChangeInformation(true) // мгновенно отрисовать изменения в абонементе на странице
    }

    //Отрисовать изменения в абонементе при работе с тренировками

    function handleAddVisitOnGroupeTraining(idClickTraining) { // групповые
        if (infoFlex && abonementClientFlexGroupe !== undefined) {
            const clickTraining = trains.find(training => training._id === idClickTraining);
            const updateListOfVisits = [...abonementClientFlexGroupe.listOfVisits, clickTraining];

            setAbonementClientFlexGroupe(prevAbonement => ({
                ...prevAbonement,
                quantityVisits: abonementClientFlexGroupe.quantityVisits - 1,
                listOfVisits: updateListOfVisits,
            })
            )
        } else if (infoDark && abonementClientDarkGroupe !== undefined) {
            const clickTraining = trains.find(training => training._id === idClickTraining);
            let updateListOfVisits = [...abonementClientDarkGroupe.listOfVisits, clickTraining];

            setAbonementClientDarkGroupe(prevAbonement => ({
                ...prevAbonement,
                quantityVisits: abonementClientDarkGroupe.quantityVisits - 1,
                listOfVisits: updateListOfVisits,
            })
            )
        }

    }

    function handleAddVisitOnPersonalTraining(idClickTraining) { // персональные
        if (infoFlex && abonementClientFlexPersonal !== undefined) {

            const clickTraining = trains.find(training => training._id === idClickTraining);
            const updateListOfVisits = [...abonementClientFlexPersonal.listOfVisits, clickTraining];

            setAbonementClientFlexPersonal(prevAbonement => ({
                ...prevAbonement,
                quantityVisits: abonementClientFlexPersonal.quantityVisits - 1,
                listOfVisits: updateListOfVisits
            })
            )
        } else if (infoDark && abonementClientDarkPersonal !== undefined) {

            const clickTraining = trains.find(training => training._id === idClickTraining);
            const updateListOfVisits = [...abonementClientDarkPersonal.listOfVisits, clickTraining];

            setAbonementClientDarkPersonal(prevAbonement => ({
                ...prevAbonement,
                quantityVisits: abonementClientDarkPersonal.quantityVisits - 1,
                listOfVisits: updateListOfVisits
            })
            )
        }
    }

    function handleRemoveVisitOnGroupeTraining(idClickTraining) { // групповые
        if (infoFlex && abonementClientFlexGroupe !== undefined) {

            const updateListOfVisits = abonementClientFlexGroupe.listOfVisits.filter(training => training._id !== idClickTraining);

            setAbonementClientFlexGroupe(prevAbonement => ({
                ...prevAbonement,
                quantityVisits: abonementClientFlexGroupe.quantityVisits + 1,
                listOfVisits: updateListOfVisits,
            })
            )
        } else if (infoDark && abonementClientDarkGroupe !== undefined) {

            const updateListOfVisits = abonementClientDarkGroupe.listOfVisits.filter(training => training._id !== idClickTraining);

            setAbonementClientDarkGroupe(prevAbonement => ({
                ...prevAbonement,
                quantityVisits: abonementClientDarkGroupe.quantityVisits + 1,
                listOfVisits: updateListOfVisits,
            })
            )
        }
    }

    function handleRemoveVisitOnPersonalTraining(idClickTraining) { // персональные
        if (infoFlex && abonementClientFlexPersonal !== undefined) {
            setAbonementClientFlexPersonal(prevAbonement => ({
                ...prevAbonement,
                quantityVisits: abonementClientFlexPersonal.quantityVisits + 1,
                listOfVisits: abonementClientFlexPersonal.listOfVisits.filter(training => training._id !== idClickTraining)
            })
            )
        } else if (infoDark && abonementClientDarkPersonal !== undefined) {
            setAbonementClientDarkPersonal(prevAbonement => ({
                ...prevAbonement,
                quantityVisits: abonementClientDarkPersonal.quantityVisits + 1,
                listOfVisits: abonementClientDarkPersonal.listOfVisits.filter(training => training._id !== idClickTraining)
            })
            )
        }
    }

    //Изменить список истории посещений
    function handleChangeListVisits(state) {
        setListOfVisits(state)
    }

    // РАБОТА С КНОПКАМИ РАСПИСАНИЯ И АКТИВНЫХ ЗАПИСЕЙ
    function onClickOnTimeTable() {
        setClickOnTimeTable(true);
        setClickOnActiveTimeTable(false);
    }

    function onClickOnActiveTimeTable() {
        setClickOnTimeTable(false);
        setClickOnActiveTimeTable(true);
    }

    function handleClickMessage() { // указать номер телефона на который пойдет сообщение
        const phoneNumber = '+79995655605'; // Замените на нужный номер телефона
        const url = `https://wa.me/${phoneNumber}`;
        window.open(url, '_blank');
    };

    function handleClickInfoTab() {
        document.querySelector('.infotab').classList.add('infotab_visible')
    }

    //АВТОРИЗАЦИЯ ---------------
    //Открыть форму авторизации
    function handleClickSignIn() {
        setIsOpenFormSignIn(true)
    }

    //Свернуть форму
    function handleCloseFormSignIn() {
        setIsOpenFormSignIn(false)
    }

    //Сабмит 
    function handleSubmitSignIn(date) {
        apiAuthClient.loginClient(date)
            .then(() => {
                setIsInfoToolOpen(true);
                setTextMassageInfoTool('Успешно')
                setIsAuthClient(true);
                setIsOpenFormSignIn(false);
                setTimeout(() => { setIsInfoToolOpen(false) }, 3000)
            }).catch((err) => {
                setIsInfoToolOpen(true);
                setIsOpenFormSignIn(false);
                setTextMassageInfoTool(`${err.message}`)
                setTimeout(() => { setIsInfoToolOpen(false) }, 5000)
            });

    }

    //ИНФОТУЛ--------------
    function handleCloseInfoTool() {
        setIsInfoToolOpen(false);
    }

    //qrcode
    //открыть qrcode
    function openQrCode() {
        setIsOpenQrCode(true);
    }

    //свернуть qrcode
    function closeQrCode() {
        setIsOpenQrCode(false);
    }

    // проверка авторизации пользователя через получение текущей информации о пользователе
    useEffect(() => {
        setIsPreloaderAuthWaiting(true); // показать страницу загрузки авторизации

        apiAuthClient.getSelfClient()
            .then(clientInfo => {
                setIsAuthClient(true); // клиент авторизован
                setClient(true); // клиент есть в БД
                setClientData(clientInfo.user);
                setIdClient(clientInfo.user._id)
                setNameClient(clientInfo.user.name);
                setSurnameClient(clientInfo.user.surname)
                setAbonementClientFlexGroupe(clientInfo.user.abonementFlexGroupe);
                setAbonementClientFlexPersonal(clientInfo.user.abonementFlexPersonal);
                setAbonementClientDarkGroupe(clientInfo.user.abonementDarkGroupe);
                setAbonementClientDarkPersonal(clientInfo.user.abonementDarkPersonal);
                setIsClientInformationLoader(false); // лоадер
                setIsPreloaderAuthWaiting(false); // спрятать страницу загрузки авторизации
            })
            .catch(error => {
                setIsAuthClient(false); // клиент не авторизован
                setClient(false)
                console.error(error);
                setIsClientInformationLoader(false); //лоадер
                setIsPreloaderAuthWaiting(false); // спрятать страницу загрузки авторизации
            });
    }, [isAuthClient])

    useEffect(() => { // сбросить стейт для контроля изменений на странице
        if (isChangeInformation) {
            setTimeout(() => { setIsChangeInformation(false) }, 1000)
        }
    }, [isChangeInformation])



    useEffect(() => { // получить текущую дату и загрузить тренировки
        // Общее расписание
        if (isClickOnTimeTable) {
            setIsLoaderTraining(true); // лоадер
            let date = new Date();
            api.getTrainsOfDate(date)
                .then((trains) => {
                    let listTrains = trains.trains.sort((a, b) => new Date(a.dateOn) - new Date(b.dateOn));
                    setTrains(listTrains);
                    setIsLoaderTraining(false);
                }).catch((err) => {
                    console.log(err);
                    setIsLoaderTraining(false);
                });
            //Активные записи
        } else if (isClickOnActiveTimeTable && isAuthClient) {
            setIsLoaderActiveTraining(true); // отобразить лоадер
            api.getTrainsOfClientsVisit(idClient) // получить все тренировки, на которые записан клиент
                .then((trains) => {
                    let filteredTrains = trains.trains.filter((train) => new Date(train.dateOn) >= new Date());
                    setTrains(filteredTrains);
                    setIsLoaderActiveTraining(false);
                }).catch((err) => {
                    console.log(err);
                    setIsLoaderActiveTraining(false);
                });
        }
    }, [isClickOnActiveTimeTable, isClickOnTimeTable, isAuthClient])


    // проставить массив посещений тренировок (общий)
    useEffect(() => {

        let visits = []

        if (infoFlex) {
            if (abonementClientFlexGroupe !== undefined
                && abonementClientFlexGroupe !== null
                && abonementClientFlexGroupe.listOfVisits !== undefined) {
                visits = [...visits, ...abonementClientFlexGroupe.listOfVisits];

            }
            if (abonementClientFlexPersonal !== undefined
                && abonementClientFlexPersonal !== null
                && abonementClientFlexPersonal.listOfVisits !== undefined) {
                visits = [...visits, ...abonementClientFlexPersonal.listOfVisits];

            }

            const visitedTrainsClient = visits.filter(training => new Date(training.dateTo) <= new Date());
            setListOfVisits(visits) // все тренировки из листа посещений
            setVisitedTrainsToClients(visitedTrainsClient) // тренировки, уже посещенные
        }

        if (infoDark) {
            if (abonementClientDarkGroupe !== undefined
                && abonementClientDarkGroupe !== null
                && abonementClientDarkGroupe.listOfVisits !== undefined) {
                visits = [...visits, ...abonementClientDarkGroupe.listOfVisits];

            }
            if (abonementClientDarkPersonal !== undefined
                && abonementClientDarkPersonal !== null
                && abonementClientDarkPersonal.listOfVisits !== undefined) {
                visits = [...visits, ...abonementClientDarkPersonal.listOfVisits];

            }

            const visitedTrainsClient = visits.filter(training => new Date(training.dateTo) <= new Date());
            setListOfVisits(visits) // все тренировки из листа посещений
            setVisitedTrainsToClients(visitedTrainsClient) // тренировки, уже посещенные
        }


    }, [
        abonementClientFlexGroupe,
        abonementClientFlexPersonal,
        abonementClientDarkGroupe,
        abonementClientDarkPersonal,
        isAuthClient,
        infoFlex,
        infoDark,
    ])

    // наполнить массив групповых тренировок из общего списка тренировок
    useEffect(() => {
        const filteredDate = trains.filter(obj => obj.typeOfTraining === "групповая");
        setGroupeTrains(filteredDate);

    }, [trains])

    //Передавать аворизацию клиента в главный компонент
    useEffect(() => {
        handleIsAuthClient(isAuthClient);
    }, [isAuthClient])

    //Работа с уведомлениями 
    function handleOnNotification() {
        console.log("вкл")

        getClientToken()
        // Проверяем, поддерживает ли браузер уведомления
        // if ('Notification' in window) {
        //     // Проверяем текущий статус разрешения на уведомления
        //     if (Notification.permission === 'granted') {
        //         console.log('Уведомления уже разрешены');
        //     } else if (Notification.permission === 'denied') {
        //         console.log('Уведомления отклонены');
        //     } else {
        //         // Запрашиваем разрешение на уведомления
        //         Notification.requestPermission().then(permission => {
        //             if (permission === 'granted') {
        //                 console.log('Уведомления разрешены');
        //                 // Здесь можно выполнять дополнительные действия, например, сохранить разрешение на сервере
        //             } else {
        //                 console.log('Уведомления отклонены');
        //             }
        //         });
        //     }
        // } else {
        //     console.log('Браузер не поддерживает уведомления');
        // }





        if (clientData.pushDeviceTokenFCM) { // если уже есть токен

        } else { // если токена нет

        }

        //api.enableNotifications()
    }

    function handleOffNotification() {
        console.log("выкл")
    }

    return (

        <>
            {isInfoToolOpen ?
                <InfoTooltip
                    isOpen={isInfoToolOpen}
                    isClose={handleCloseInfoTool}
                    text={isTextMassageInfoTool}
                    backgoundColor={backgoundColorForm} // цвет фона
                />
                : <></>}
            {/* ФОРМА АВТОРИЗАЦИИ */}
            {isOpenFormSignIn ?
                <FormSignIn
                    isOpen={isOpenFormSignIn}
                    isClose={handleCloseFormSignIn}
                    onSubmit={handleSubmitSignIn}
                    colorBackground={backgoundColorForm}
                />
                : <></>}
            {/* Информационная страница по правилам студии */}
            <InfoTab
                backgroundColor={backgoundColorForm} // цвет фона
                api={api} // передать АПИ студии
                phoneWhatsApp={phoneWhatsApp} // сотовый студии
                handleOnNotification={handleOnNotification} // слушатель на включение уведомлений
                handleOffNotification={handleOffNotification} // слушатель на выключение уведомления
                stateEnableNotification={clientData.pushNotificationsEnabled} // текущее состояние вкл уведомлений
            />
            {/* QRCode */}
            {isOpenQrCode ?
                <QRCode
                    api={api}
                    backgoundColor={backgoundColorForm}
                    isOpen={isOpenQrCode} // управлять открытием
                    isClose={closeQrCode}
                />
                : <></>}
            {isPreloaderAuthWaiting ?
                <LoadingPage backgroundColor={backgroundColor} />
                :
                isAuthClient ?
                    <div className={`page ${backgroundColor} `}>
                        {/* ШАПКА */}
                        <header className={`page__header`}>
                            <div className={`page__header-link-container`}>
                                <Link to="/flex" className={`mainPage__link-page ${textMainTitleColor} `}>Flex Lab</Link>
                                <Link to="/dark" className={`mainPage__link-page mainPage__link-page_Dark`}>DARK</Link>
                            </div>
                            <p className={`mainPage__link-page__description ${textMainTitleColor}`}>stretching & dance</p>
                        </header>
                        {/* ИНФОРМАЦИЯ О КЛИЕНТЕ */}
                        <div className="page__subscription-information">
                            <ClientInfo
                                textTitleColor={textTitleColor} //цвет текста титульников
                                textMainTitleColor={textMainTitleColor} // цвет заголовка страницы
                                infoFlex={infoFlex} // давать ли информацию по абонементам  Flex
                                infoDark={infoDark} // давать ли информацию по абонементам  Dark
                                nameClient={nameClient}
                                surnameClient={surnameClient}
                                backgroundColor={backgroundColor}
                                abonementClientFlexGroupe={abonementClientFlexGroupe}
                                abonementClientFlexPersonal={abonementClientFlexPersonal}
                                abonementClientDarkGroupe={abonementClientDarkGroupe}
                                abonementClientDarkPersonal={abonementClientDarkPersonal}
                                isShowAllAbonements={isShowAllAbonements} // показывать инфу о всех абонементах или только о заведенных в базу ( true - всех, false - заведенных в базу)
                                client={isAuthClient} // проверка есть ли клиент в БД, если нет, то не показывать инф об абонементе (true - клиент есть в БД)
                                admin={false} // не страница админа
                                isLoader={isClientInformationLoader} // лоадер
                                textColor={textTitleColor} // цвет текста
                            />
                        </div>

                        {isClickOnTimeTable ?
                            // РАСПИСАНИЕ ГРУППОВЫХ ТРЕНИРОВОК
                            <TimeTable
                                api={api} // передать АПИ студии
                                trains={groupeTrains} // массив тренировок
                                setTrains={handleSetTrains} // менять стейт массива тренировок
                                textTitleColor={textTitleColor} // цвет шрифта
                                styleText={styleText} // стиль текста
                                titleTimeTable={titleTimeTable} // заголовок расписания
                                timetableButtonStyle={timetableButtonStyle} // стиль кнопки переключения недели
                                timeTableBackgroundColor={backgroundColor}
                                statePage={isClickOnTimeTable} // показ в расписании тренировок общих либо личных тру - общих
                                setChangeVisit={setChangeVisit}
                                idClient={idClient} // айди клиента
                                client={client} // проверка есть ли клиент в БД, если нет, то не показывать инф об абонементе (true - клиент есть в БД)
                                timetableWeekButtonStyle={timetableWeekButtonStyle} // цвет фона кнопок дней недели
                                disabledButtonTrain={false} // работает ли клик на тренировку или нет, true - не работает, нужно для отображения компонента списка посещений
                                setIsChangeInfo={handleChangeInformation} // мгновенно отрисовать изменения в абонементе на странице
                                loaderTimeTable={isLoaderTraining}
                                isAdmin={false} // true - страница админа, false - клиента, используется для записи на разовые тренировки
                                isTypeSpace={infoDark} // выбор зала, true - добавить выбор 
                                backgroundColorForm={backgoundColorForm}
                                handleAddVisitOnGroupeTraining={handleAddVisitOnGroupeTraining} // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
                                handleAddVisitOnPersonalTraining={handleAddVisitOnPersonalTraining} // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
                                handleRemoveVisitOnGroupeTraining={handleRemoveVisitOnGroupeTraining} // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
                                handleRemoveVisitOnPersonalTraining={handleRemoveVisitOnPersonalTraining} // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
                            />
                            // АКТИВНЫЕ ЗАПИСИ НА ТРЕНИРОВКИ И ИСТОРИЯ ПОСЕЩЕНИЙ
                            :
                            <>
                                <TimeTable
                                    api={api} // передать АПИ студии
                                    trains={trains} // массив тренировок
                                    setTrains={handleSetTrains} // менять стейт массива тренировок
                                    handleChangeListVisits={handleChangeListVisits} // менять стейт списка посещений
                                    textTitleColor={textTitleColor} // цвет шрифта
                                    styleText={styleText} // стиль текста
                                    titleTimeTable={"Активные записи"} // заголовок расписания
                                    timetableButtonStyle={timetableButtonStyle} // стиль кнопки переключения недели
                                    timeTableBackgroundColor={backgroundColor}
                                    statePage={isClickOnTimeTable} // показ в расписании тренировок общих либо личных тру - общих
                                    setChangeVisit={setChangeVisit}
                                    idClient={idClient} // айди клиента
                                    timetableWeekButtonStyle={timetableWeekButtonStyle} // цвет фона кнопок дней недели
                                    disabledButtonTrain={false} // работает ли клик на тренировку или нет, true - не работает, нужно для отображения компонента списка посещений
                                    setIsChangeInfo={handleChangeInformation} // мгновенно отрисовать изменения в абонементе на странице
                                    loaderTimeTable={isLoaderActiveTrainingActive} // лоадер
                                    isAdmin={false} //
                                    isTypeSpace={infoDark} // выбор зала, true - добавить выбор 
                                    backgroundColorForm={backgoundColorForm}
                                    handleAddVisitOnGroupeTraining={handleAddVisitOnGroupeTraining} // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
                                    handleAddVisitOnPersonalTraining={handleAddVisitOnPersonalTraining} // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
                                    handleRemoveVisitOnGroupeTraining={handleRemoveVisitOnGroupeTraining} // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
                                    handleRemoveVisitOnPersonalTraining={handleRemoveVisitOnPersonalTraining} // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
                                />
                                {/* ИСТОРИЯ ПОСЕЩЕНИЙ */}
                                <TimeTable
                                    api={api} // передать АПИ студии
                                    trains={visitedTrainsToClient} // массив тренировок
                                    setTrains={handleSetTrains} // менять стейт массива тренировок
                                    textTitleColor={textTitleColor} // цвет шрифта
                                    styleText={styleText} // стиль текста
                                    titleTimeTable={"История посещений"} // заголовок расписания
                                    timetableButtonStyle={timetableButtonStyle} // стиль кнопки переключения недели
                                    timeTableBackgroundColor={backgroundColor}
                                    statePage={isClickOnTimeTable} // показ в расписании тренировок общих либо личных тру - общих
                                    setChangeVisit={setChangeVisit}
                                    idClient={idClient} // айди клиента
                                    timetableWeekButtonStyle={timetableWeekButtonStyle} // цвет фона кнопок дней недели
                                    disabledButtonTrain={true} // работает ли клик на тренировку или нет, true - не работает, нужно для отображения компонента списка посещений
                                    timetableListTextStyle={'timetable__history-list-text-style'} // стиль для текста в истории посещений
                                    setIsChangeInfo={handleChangeInformation} // мгновенно отрисовать изменения в абонементе на странице
                                    loaderTimeTable={isLoaderHistoryVisitsTraining}
                                    isAdmin={false}
                                    isTypeSpace={infoDark} // выбор зала, true - добавить выбор 
                                />
                            </>
                        }
                        <footer className={`page__footer ${backgroundColor}  ${styleFooter}`}>
                            <>
                                {isAuthClient ?
                                    !isClickOnTimeTable ?
                                        <>
                                            <button className={`page__imageWA-button flex`} onClick={onClickOnTimeTable}>
                                                <img className='page__imageWA' src={imgMainPage} alt="imgClientTrain" />
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button className={`page__imageWA-button flex`} onClick={onClickOnActiveTimeTable}>
                                                <img className='page__imageWA' src={imgClientTrain} alt="imgClientTrain" />
                                            </button>
                                            <button className={`page__imageWA-button flex`} onClick={handleClickInfoTab}>
                                                <img className='page__imageInfoTab' src={imgInfoTab} alt="инфотаб" />
                                            </button>
                                        </>
                                    :
                                    // АВТОРИЗАЦИЯ
                                    <button className={`page__imageWA-button flex`} onClick={handleClickSignIn}>
                                        <img className='page__imageInfoTab' src={imgSignIn} alt="imgSignIn" />
                                    </button>
                                }
                                <button className={`page__imageWA-button flex`} onClick={openQrCode}>
                                    <img className='page__imageWA' src={imgQrCode} alt="imgQrCode" />
                                </button>
                                <button className={`page__imageWA-button flex`} onClick={handleClickMessage}>
                                    <img className='page__imageWA' src={imageWA} alt="мессенджер" />
                                </button>
                            </>
                        </footer >
                    </div>
                    : <SignIn
                        onSubmit={handleSubmitSignIn}
                        colorBackground={backgoundColorForm}
                        infoFlex={infoFlex}
                        infoDark={infoDark}
                        message={isTextMassageInfoTool}
                    />
            }
        </>
    );
}

export default PageStudio;