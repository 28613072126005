import { useEffect, useState } from "react";
import InfoTooltip from "../InfoTooltip/InfoTooltip"
import Preloader from "../Preloader/Preloader";

function QRCode({
    backgoundColor,
    isOpen, // управлять открытием
    isClose,
    api,
}) {

    const [qrCodeURL, setQRCodeURL] = useState('');
    const [text, setText] = useState('');
    const [isLoader, setIsLoaderState] = useState(false); // true - отображать loader

    useEffect(() => {
        setIsLoaderState(true);
        // Функция для получения QR-кода с сервера
        api.getQrCodeClient()
            .then(res => {
                setIsLoaderState(false);
                setQRCodeURL(res.URL);
            }).catch(err => {
                setText('Произошла ошибка при загрузке QRCode')
                setIsLoaderState(false);
             });

        // Очистка URL при завершении компонента или изменении значения isOpen
        return () => {
            if (qrCodeURL) {
                URL.revokeObjectURL(qrCodeURL);
            }
        };
    }, [isOpen]);

    return (
        <InfoTooltip
            isOpen={isOpen} // управлять открытием
            isClose={isClose} // управлять сворачиваением
            text={text} // передать текст
            showButton={false} // показывать кнопки или нет
            backgoundColor={"transparent"} // цвет фона
            children={// дочерний элемент
                isLoader ?
                    <Preloader />
                    :
                    <img className='qrcode' src={qrCodeURL} alt="QR Code" />
            }
        />
    )
}

export default QRCode;