class ApiAuthClient {
    constructor(data) {
        this.host = data.host;
    }

    // проверка статуса запроса
    _getResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            return Promise.reject(
                `ошибка: ${res.status} - ${res.statusText}`
            );
        }
    }

    //Авторизация
    loginClient(data) {
        return fetch(`${this.host}/client/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ login: data.login, password: data.password, })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Получить инфо о клиенте(автоматом вкладывает _id в req.user, если авторизован)
    getSelfClient() {
        return fetch(`${this.host}/flex/client`,
            {
                method: 'GET',
                credentials: 'include'
            }
        ).then((res) => this._getResponse(res))
    }
}

const apiAuthClient = new ApiAuthClient({
    host: 'https://api.darkflex.ru',
    //host: 'https://www.api.darkflex.ru',
    //host: 'http://localhost:3000',
});


export default apiAuthClient;