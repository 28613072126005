function FormAdd({
    name,
    isOpen,
    styleAllForm, // стиль всей формы
    isClose,
    text, // заголовок формы
    children,
    onSubmit, // самбмит формы
    validationOptions,
    nameForm, // стиль формы( боксшадоу и размеры)
    buttonCloseDisable, // задисеблить кнопку сворачивания
    titleColorControl,
    buttonVisibleControl, // спрятать кнопку сворачивания
    textButtonSubmit,
    colorButtonSubmit, // стиль кнопки сабмита
    buttonUnderText,
    colorBackground, // цвет фона при необходимости
}) {

    return (
        <div className={`formadd ${styleAllForm} formadd_overlay ${isOpen ? "formadd_show" : ""}`}>
            <div className={`formadd__content ${styleAllForm}`}>
                {!buttonCloseDisable ?
                    <button className={`formadd__close-button  ${buttonVisibleControl}`} type="button" onClick={isClose} disabled={buttonCloseDisable} />
                    : <></>}
                <form className={`formadd__form ${colorBackground} ${nameForm}`} name={`${name}`} onSubmit={onSubmit}>
                    <h2 className={`formadd__title ${titleColorControl}`}>{text}</h2>
                    <div className="formadd__set">
                        {children}
                    </div>
                    <button className={`formadd__button ${colorButtonSubmit}`} type="submit" disabled={validationOptions}>{textButtonSubmit}</button>
                    <span className="formadd__button-under-text">{buttonUnderText}</span>
                </form>
            </div>
        </div>
    )
}

export default FormAdd;