import apiFlex from '../../utils/ApiFlex'; // АПИ
import apiAuthClient from '../../utils/ApiAuthClient'; // АПИ авторизации клиента
import PageStudio from "../PageStudio/PageStudio"; // компонент студии

import imageWA from '../../images/message-three-points-1560-svgrepo-com.svg';
import imgClientTrain from '../../images/note-text-svgrepo-com.svg';
import imgMainPage from '../../images/home-1-svgrepo-com.svg';
import imgInfoTab from '../../images/attention-circle-svgrepo-com.svg';
import imgSignIn from '../../images/signin-svgrepo-com.svg' // картинка авторизации
import imgQrCode from '../../images/qrcode-svgrepo-com.svg' // картинка qrcode

//ИНСТРУКЦИЯ:
//получения массива тренировок и Сортировка массива происходит в DayList в зависмости от дня недели

function Flex({
    loaderTimeTable, // получить из Апп стейт лоадера обратно и передать в компонент TimeTable ( таблица тренировок)
    setIsInfoToolOpen, // управлять открытием инфотула
    handleIsAuthClient,
}) {


    function handleClickWA() {
        const phoneNumber = '89995602900'; // Замените на нужный номер телефона
        const url = `https://wa.me/${phoneNumber}`;
        window.open(url, '_blank');
    };

    return (
        <PageStudio
            api={apiFlex} // передать АПИ студии
            apiAuthClient={apiAuthClient} // АПИ авторизации клиента
            backgroundColor={'flex'} // цвет фона
            textTitleColor={'flex__color-text'} // цвет всего текста на странице
            colorBackgroundForm={'flex'} // цвет фона формы сабмита нового клиента
            timetableWeekButtonStyle={'flex__button-day-weeks'} // стиль кнопок переключения дней недели
            textMainTitleColor={'flex__title-color-text'}
            title={'Flex'}
            subTitle={'stetching & plastica'}
            textButton={'Личный кабинет'} // текст кнопки перехода в ЛК
            titleTimeTable={'Записаться на тренировку'} // заголовок таблицы расписания
            styleText={'flex__color-text'}
            styleButton={'flex__buttons'} // стиль кнопок
            link={'/flex/myaccount'} // ссылка кнопки в ЛК
            loaderTimeTable={loaderTimeTable}
            timetableButtonStyle={"list-days__button-change-week_backround-color_flex"} // передать цвет фона кнопок переключения недели в таблицу
            setIsInfoToolOpen={setIsInfoToolOpen}// управлять открытием инфотула
            isShowAllAbonements={false} // показывать инфу о всех абонементах или только о заведенных в базу ( true - всех, false - заведенных в базу)
            infoFlex={true} // давать ли информацию по абонементам Flex
            infoDark={false} // давать ли информацию по абонементам Dark
            imgClientTrain={imgClientTrain} // картинка кнопки "Мои тренировки"
            imageWA={imageWA} // картинка кнопки WA
            imgMainPage={imgMainPage} // картинка кнопки "На главную"
            imgInfoTab={imgInfoTab} // картинка иконки инфы
            imgSignIn={imgSignIn} // картинка авторизациии
            imgQrCode ={imgQrCode} // картинка qrcode
            phoneWhatsApp={'+79995602900'} // сотовый студии
            backgoundColorForm={'flex'} // цвет бэка форм
            handleIsAuthClient={handleIsAuthClient}
        />
    )
}

export default Flex;