import { useEffect, useState } from "react";

function LoadingPage({ backgroundColor }) {

    const [name, setName] = useState('');

    useEffect(() => {
        if (backgroundColor.includes('flex')) {
            setName('Flex');
        } else {
            setName('DARK');
            document.querySelector('.page-loading__name').classList.add('page-loading__name-dark')
        }
    }, [backgroundColor])

    useEffect(() => {
        // Создаем интервал при монтировании компонента
        const interval = setInterval(() => {
            document.querySelector('.page-loading__name').classList.toggle('page-loading__name-color')
        }, 1000);

        // Очищаем интервал при размонтировании компонента
        return () => clearInterval(interval);
    }, [])

    return (

        <div className={`page-loading ${backgroundColor}`}>
            <p className="page-loading__name">{name}</p>
            {/* <Preloader /> */}
        </div>
    )
}

export default LoadingPage;