import { useEffect, useState } from "react";
import FormAdd from "../FormAdd/FormAdd";

function FormSignIn({
    isOpen,
    isClose,
    onSubmit, // действия при сабмите
    colorBackground, // цвет фона
}) {

    // Стейты, в которых содержятся значения инпута
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [validationMassegeLogin, setValidationMassegeLogin] = useState('Логин');
    const [validationMassegePassword, setValidationMassegePassword] = useState('Пароль');
    //
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    //передать данные  из инпутов
    function handleChangeLogin(e) {
        setLogin(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegeLogin('Логин');
        } else {
            setValidationMassegeLogin(e.target.validationMessage);
        }
    }

    function handleChangePassword(e) {
        setPassword(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegePassword('Пароль');
        } else {
            setValidationMassegePassword(e.target.validationMessage);
        }
    }

    //Передать данные = отправка на сервер 
    function handleSubmit(e) {
        e.preventDefault();
        onSubmit({
            login: login,
            password: password
        });
    }

    //Отслеживать изменения в инпута, кнопка задисейблена пока нет внесены изменения 
    useEffect(() => {
        const allowedCharactersRegex = /^[a-zA-Zа-яА-Я0-9]+$/;
        if (
            login === '' ||
            password === '' ||
            validationMassegePassword !== 'Пароль' ||
            validationMassegeLogin !== 'Логин' ||
            !allowedCharactersRegex.test(login) ||
            !allowedCharactersRegex.test(password)
        ) {
            setIsSubmitDisabled(true);
        } else {
            setIsSubmitDisabled(false);
        }

    }, [login, password, validationMassegePassword, validationMassegeLogin]);


    return (
        <FormAdd
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={isClose} // клик на кнопку для сворачивания
            onSubmit={handleSubmit}
            text={'Авторизация'}
            textButtonSubmit={'Вход'}
            colorBackground={colorBackground}
            validationOptions={isSubmitDisabled} // дисейблить сабмит если true
            children={
                <>
                    <div className="formadd__input-conainer">
                        <input value={login} type="text" placeholder="Логин" className={`${colorBackground} formadd__input formadd__input_name_image`}
                            name="name-admin" id="name-admin" minLength="2" maxLength="30" onChange={handleChangeLogin} required />
                        <span className="formadd__text-error" id="error-firstname">{validationMassegeLogin}</span>
                    </div>
                    <div className="formadd__input-conainer">
                        <input value={password} type="text" placeholder="Пароль" className={`${colorBackground} formadd__input formadd__input_name_image`}
                            name="password-admin" id="password-admin" minLength="1" maxLength="50" onChange={handleChangePassword} required />
                        <span className="formadd__text-error" id="error-firstname">{validationMassegePassword}</span>
                    </div>
                </>
            }
        />
    )
}

export default FormSignIn;