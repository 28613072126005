import apiDark from '../../utils/ApiDark'; // АПИ
import apiAuthClient from '../../utils/ApiAuthClient'; // АПИ авторизации клиента
import PageStudio from "../PageStudio/PageStudio"; // компонент студии

import imageWA from '../../images/message-three-points-1560-svgrepo-com.svg';
import imgClientTrain from '../../images/note-text-svgrepo-com.svg';
import imgMainPage from '../../images/home-1-svgrepo-com.svg';
import imgInfoTab from '../../images/attention-circle-svgrepo-com.svg';
import imgSignIn from '../../images/signin-svgrepo-com.svg';
import imgQrCode from '../../images/qrcode-svgrepo-com.svg' // картинка qrcode

//ИНСТРУКЦИЯ:
//получения массива тренировок и Сортировка массива происходит в DayList в зависмости от дня недели

function Dark({
    loaderTimeTable, // получить из Апп стейт лоадера обратно и передать в компонент TimeTable ( таблица тренировок)
    setIsInfoToolOpen, // управлять открытием инфотула
    handleIsAuthClient,
}) {

    //const idClient = "6461e66c0dd9a7f57f0aaebf"; // захардкоденный айди клиента для теста

    return (
        <PageStudio
            api={apiDark} // передать АПИ студии
            apiAuthClient = {apiAuthClient} // АПИ авторизации клиента
            backgroundColor={'dark'} // цвет фона
            textTitleColor={'flex__color-text'} // цвет титульника текста на странице
            styleText={'dark__color-text'} // стиль текста
            styleFooter={'dark__footer'}
            colorBackgroundForm={'dark__form'} // цвет фона формы сабмита нового клиента
            textMainTitleColor={'flex__title-color-text'}
            title={'Dark'}
            subTitle={'dancing & plastica'}
            textButton={'Личный кабинет'} // текст кнопки перехода в ЛК
            titleTimeTable={'Записаться на тренировку'} // заголовок таблицы расписания
            styleButton={'flex__buttons'} // стиль кнопок
            link={'/dark/myaccount'} // ссылка кнопки в ЛК
            loaderTimeTable={loaderTimeTable}
            timetableButtonStyle={"list-days__button-change-week_backround-color_flex"} // передать цвет фона кнопок переключения недели в таблицу
            styleDayDateText={"dark__day-date-text"} // стиль текста под кнопкой дня недели для переключения
            timetableWeekButtonStyle={'dark__button-day-weeks'} // стиль кнопок переключения дней недели
            setIsInfoToolOpen={setIsInfoToolOpen}// управлять открытием инфотула
            isShowAllAbonements={false} // показывать инфу о всех абонементах или только о заведенных в базу ( true - всех, false - заведенных в базу)
            infoFlex={false} // давать ли информацию по абонементам Flex
            infoDark={true} // давать ли информацию по абонементам Dark
            imgClientTrain={imgClientTrain} // картинка кнопки "Мои тренировки"
            imageWA={imageWA} // картинка кнопки WA
            imgMainPage={imgMainPage} // картинка кнопки "На главную"
            imgInfoTab={imgInfoTab} // картинка иконки инфы
            imgSignIn={imgSignIn} // картинка авторизации
            imgQrCode ={imgQrCode} // картинка qrcode
            phoneWhatsApp={'+79995602900'} // сотовый студии
            backgoundColorForm={'dark__form'} // цвет бэка форм
            handleIsAuthClient ={handleIsAuthClient}
        />
    )
}

export default Dark;