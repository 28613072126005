import React, { useState } from 'react';

const Checkbox = ({ text, handleClick }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => {
        setIsChecked((prev) => !prev);
        handleClick(!isChecked);
    };

    return (
        <label className='checkbox'>
            <input type="checkbox" className='checkbox__option' checked={isChecked} onChange={handleToggle} />
            {text}
        </label >
    );
};

export default Checkbox;
