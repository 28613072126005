
import { useEffect, useState } from "react";
import FormAdd from "../FormAdd/FormAdd";
import InfoTooltip from "../InfoTooltip/InfoTooltip";

import Checkbox from "../Checkbox/Checkbox";

function SignIn({
    onSubmit,
    message,
}) {

    // Стейты, в которых содержятся значения инпута
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [validationMassegeLogin, setValidationMassegeLogin] = useState('Логин');
    const [validationMassegePassword, setValidationMassegePassword] = useState('Пароль');
    //Управление
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    //Инфотул
    const [isOpenInfoTool, setIsOpenInfoTool] = useState(false);
    const [messageInfoTool, setMessageInfoTool] = useState('')
    //Чекбоксы
    const [isCheckedRules, setIsCheckedRules] = useState(false);
    const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(false);

    //Зарегистрироваться(отправить сообщение админу)
    function SignUp() {
        const phoneNumber = '+79995655605'; // Замените на нужный номер телефона
        const message = 'Добрый день! Прошу зарегистрировать меня для доступа к приложению'; // Предзаполненное сообщение
        const encodedMessage = encodeURIComponent(message);
        const url = `https://wa.me/${phoneNumber}?p=${encodedMessage}`;
        window.open(url, '_blank');
    }

    //передать данные  из инпутов
    function handleChangeLogin(e) {
        setLogin(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegeLogin('Логин');
        } else {
            setValidationMassegeLogin(e.target.validationMessage);
        }
    }

    function handleChangePassword(e) {
        setPassword(e.target.value);
        if (e.target.validationMessage === "") {
            setValidationMassegePassword('Пароль');
        } else {
            setValidationMassegePassword(e.target.validationMessage);
        }
    }

    //Передать данные = отправка на сервер 
    function handleSubmit(e) {
        e.preventDefault();
        onSubmit({
            login: login,
            password: password
        });
    }


    //Инфотул
    //Свернуть инфотул
    function handleCloseInfoTool() {
        setIsOpenInfoTool(false);
    }

    //Чекбоксы
    function handleClickCheckBoxRules(state) {
        setIsCheckedRules(state)
    }

    function handleClickCheckBoxPrivacy(state) {
        setIsCheckedPrivacy(state)
    }

    //Валидация Отслеживать изменения в инпута, кнопка задисейблена пока нет внесены изменения 
    useEffect(() => {

        const allowedCharactersRegex = /^[a-zA-Zа-яА-Я0-9]+$/;
        if (
            login === '' ||
            password === '' ||
            !allowedCharactersRegex.test(login) ||
            !allowedCharactersRegex.test(password) ||
            isCheckedRules !== true ||
            isCheckedPrivacy !== true
        ) {
            setIsSubmitDisabled(true);
        } else {
            setIsSubmitDisabled(false);
        }
    }, [password, login, isCheckedRules, isCheckedPrivacy]);


    return (
        <div className={`signin`}>
            {isOpenInfoTool ?
                <InfoTooltip
                    isOpen={isOpenInfoTool}
                    isClose={handleCloseInfoTool}
                    p={messageInfoTool}
                    backgoundColor={'dark'}
                />
                : <></>}
            <FormAdd
                isOpen={true} // стейт контролирующий открытие
                onSubmit={handleSubmit}
                text={'Авторизация'}
                textButtonSubmit={'Войти'}
                colorBackground={'signin__form'}
                validationOptions={isSubmitDisabled} // дисейблить сабмит если true
                styleAllForm={'signin__form'} // стиль всей формы
                buttonCloseDisable={true}
                buttonUnderText={
                    <>
                        <Checkbox
                            text={
                                <span className="checkbox__text">Нажимая кнопку вы подтверждаете свое согласие с
                                    <a className="checkbox__link" href='https://darkflex.ru/rules'> правилами Студии </a>
                                </span>
                            }
                            handleClick={handleClickCheckBoxRules}
                        />
                        <Checkbox
                            text={
                                <span className="checkbox__text">Нажимая кнопку вы подтверждаете свое согласие с
                                    <a className="checkbox__link" href='https://darkflex/privacy'> политикой обработки персональных данных </a>
                                </span>
                            }

                            handleClick={handleClickCheckBoxPrivacy}
                        />
                        <p className="signin__message">{message}</p>
                        <button className="signin__button-signup" onClick={SignUp}>Зарегистрироваться</button>
                    </>
                }
                children={
                    <>
                        <div className="formadd__input-conainer">
                            <input value={login} type="text" placeholder="Логин" className={`signin formadd__input formadd__input_name_image`}
                                name="name-admin" id="name-admin" minLength="2" maxLength="30" onChange={handleChangeLogin} required />
                            <span className="formadd__text-error" id="error-firstname">{validationMassegeLogin}</span>
                        </div>
                        <div className="formadd__input-conainer">
                            <input value={password} type="text" placeholder="Пароль" className={`signin formadd__input formadd__input_name_image`}
                                name="password-admin" id="password-admin" minLength="1" maxLength="50" onChange={handleChangePassword} required />
                            <span className="formadd__text-error" id="error-firstname">{validationMassegePassword}</span>
                        </div>
                    </>
                }
            />
        </div >
    )
}

export default SignIn;