//ИНФОРМАЦИЯ ДЛЯ КЛИЕНТА(ОБЩИЕ ПРАВИЛА)

import { useEffect, useState } from "react";

import Selector from "../Selector/Selector"
import FormAddFreeze from "../FormAddFreeze/FormAddFreeze";

function InfoTab({
    backgroundColor, // цвет фона
    api, // передать АПИ студии
    phoneWhatsApp, // сотовый студии
    isClickOnButtonOpen, // клик на открытие инфотаба
    titleColorControl,
    handleOnNotification,
    handleOffNotification,
    stateEnableNotification,
}) {

    const [isMain, setIsMain] = useState(true);
    const [isEnableNotification, setIsEnableNotification] = useState(false);

    function closeInfoTab() {  //свернуть попап
        document.querySelector('.infotab').classList.remove('infotab_visible')
    }

    function openFreeze() {
        setIsMain(false);
    }

    function backRuls() {
        setIsMain(true);
    }

    function logOut() {
        console.log(document.cookie)

    }

    //Работа с селектором
    function clickSelector() {
        if (isEnableNotification) {
            setIsEnableNotification(false);
            handleOffNotification();
        } else {
            setIsEnableNotification(true)
            handleOnNotification();
        }
    }

    useEffect(() => {
        setIsEnableNotification(stateEnableNotification)
    }, [stateEnableNotification])

    return (
        <div className={`infotab ${backgroundColor}`}>
            <button className={`infotab__button ${backgroundColor}`} onClick={closeInfoTab}>
                <span className="infotab__button-close"></span>
            </button>
            {isMain ?
                <>
                    <div className="infotab__descriptions">
                        <h2 className="infotab__description-title">Правила студии</h2>
                        <ul>
                            <li className="infotab__description">Абонемент активируется в день покупки</li>
                            <li className="infotab__description">Запись на тренировки обязательна</li>
                            <li className="infotab__description">отмена тренировки без списания с абонемента за 3 часа;</li>
                            <li className="infotab__description">возможна отмена тренировки при записи 1 человека в группу. Об отмене администратор предупреждает в личные сообщения за 3 часа до тренировки;</li>
                            <li className="infotab__description">возможна замена тренера / изменение расписания.</li>
                        </ul>
                        <h3 className="infotab__description-title">Заморозка абонемента:</h3>
                        <ul>
                            <li className="infotab__description infotab__description_subdescription">только при предоставлении командировочных/больничных</li>
                            <li className="infotab__description infotab__description_subdescription">не более одного раза за действие абонемента</li>
                            <li className="infotab__description infotab__description_subdescription">не более 10 дней</li>
                        </ul>
                    </div>
                    <div className="infotab__buttons">
                        <Selector
                            handleOnClickOne={clickSelector} // клик на первый чек бокс
                            nameOne={'Включить уведомления'} // название первого чекбокса
                            //styleSelector, // стиль
                            switchControl={isEnableNotification} // внешний контроль отображения, true -вкл свитчер
                            onlyOn={false} // true - не возвращать селектор при повторном клике, можно только включать
                            styleCheckboxButton={'infotab__selector'} // стиль свитчера
                            styleText={'infotab__selector-text'} // стиль текста
                        />
                        <button className={`infotab__button`} onClick={openFreeze}> Заявка на заморозку абонемента</button >
                        <button className={`infotab__button`} onClick={logOut}> Выйти </button >
                    </div>
                </>
                :
                <>
                    {/* <FileForm /> */}
                    <FormAddFreeze
                        api={api}
                        phoneWhatsApp={phoneWhatsApp} // сотовый студии
                    />
                    <button className={`infotab__description page__freeze-button`} onClick={backRuls}> Вернуться к правилам</button >
                </>
            }
        </div>
    )
}

export default InfoTab;