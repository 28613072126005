import { useState } from "react";

function FormAddFreeze(
    {
        colorBackground,
        titleColorControl,
        phoneWhatsApp,
        // nameClient, // Имя клиента
        // surnameClient, // Фамилия клиента
    }
) {

    let nameClient = 'Маша'
    let surnameClient = 'Машина'

    const [typeTFreeze, setTypeTFreeze] = useState('больничный'); // тип заморозки
    const [quantityFreeze, setQuantityFreeze] = useState(); // количество дней заморозки
    const [validationMassegeQuantityFreeze, setValidationMassegeQuantityFreeze] = useState('введите количество дней согласно документу')
    const [dissableButton, setDissableButton] = useState(true); // активная или нет кнопка сабмита

    function handleChangeFreezeType(selectedOption) {
        setTypeTFreeze(selectedOption.value);
    }

    function handleChangeQuantityFreeze(e) {
        setQuantityFreeze(e.target.value);
        setValidationMassegeQuantityFreeze(e.target.validationMessage);
        if (e.target.validationMessage === '') {
            setDissableButton(false);
        } else {
            setDissableButton(true);
        }
    }

    //Отправить сообщение в WA
    function openWhatsApp(text) {
        const whatsappUrl = `https://wa.me/${phoneWhatsApp}?text=${encodeURIComponent(text)}`;
        // Открываем WhatsApp в новой вкладке/окне
        window.open(whatsappUrl);
    }

    function onSubmit(event) {
        event.preventDefault();
        let text = `Добрый день, прилагаю во вложении  ${typeTFreeze} и прошу рассмотреть заявку на заморозку абонемента` +
            ` ${nameClient} ${surnameClient}` + ' на ' + `${quantityFreeze} дней`;

        openWhatsApp(text)
    }

    return (
        <form className='formadd-freeze' onSubmit={onSubmit}>
            <h2 className={`formadd__title ${titleColorControl}`}>Заявка на заморозку абонемента</h2>
            {/* ТИП */}
            <div className="formadd__input-conainer formadd__input-conainer_freeze">
                <select
                    value={typeTFreeze}
                    onChange={handleChangeFreezeType}
                    className={`${colorBackground} formadd__input formadd__input_name_image formadd__input-select`}
                >
                    <option className="formadd__select-option" value="больничный">больничный</option>
                    <option className="formadd__select-option" value="командировочный лист">командировочный лист</option>
                </select>
                <span className="formadd__text-error" id="error-firstname">Тип заявки</span>
            </div>
            {/* Количество дней заморозки*/}
            <div className="formadd__input-conainer formadd__input-conainer_freeze">
                <input value={quantityFreeze} type="number" placeholder="Количество дней заморозки" className={`${colorBackground} formadd__input formadd__input_name_image`}
                    name="number-client" id="number-client" min="1" max="10" onChange={handleChangeQuantityFreeze} required />
                <span className="formadd__text-error" id="error-firstname">{validationMassegeQuantityFreeze}</span>
            </div>
            {/* САБМИТ */}
            <button className={`formadd__button `} type="submit" disabled={dissableButton}>Отправить заявку</button>
            <span className="formadd__text-error" id="error-firstname">Важно: после открытия чата прикрепите документы</span>
        </form>
    )
}

export default FormAddFreeze;

//   <input
//                 type="file"
//                 placeholder="Прикрепите файл"
//                 className={`${colorBackground} formadd__input formadd__input_freeze formadd__input_name_image`}
//                 name="file"
//                 id="file-upload"
//                 accept=".jpg"
//                 onChange={handleChangeFileFreeze} required />
//             <span className="formadd__text-error" id="error-firstname">{validationMassegeFile}</span>
