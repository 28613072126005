import React from 'react';
import { Link } from 'react-router-dom';

function MainPage() {
    return (
        <div className='mainPage'>
            <div className='mainPage__link-container'>
                <Link to="/flex" className='mainPage__link-page'>Flex Lab</Link>
                <Link to="/dark" className='mainPage__link-page mainPage__link-page_Dark'>DARK</Link>
            </div>
            <p className='mainPage__link-page__description'>stretching & dance</p>
        </div>
    )
}

export default MainPage;