class Api {
    constructor(data) {
        this.host = data.host;
    }

    // проверка статуса запроса
    _getResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            return Promise.reject(
                `ошибка: ${res.status} - ${res.statusText}`
            );
        }
    }
    //ДАННЫЕ Клиентов**--------ДАННЫЕ Клиентов**-------------ДАННЫЕ Клиентов**------------ДАННЫЕ Клиентов**---------------ДАННЫЕ Клиентов**-----------------------------------
    //пробросить данные для регистрации через АПИ

    //Авторизация
    loginClient(data) {
        return fetch(`${this.host}/client/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ login: data.login, password: data.password, })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Получить инфо о клиенте(автоматом вкладывает _id в req.user, если авторизован)
    getSelfClient() {
        return fetch(`${this.host}/dark/client`,
            {
                method: 'GET',
                credentials: 'include'
            }
        ).then((res) => this._getResponse(res))
    }

    //получить qrcode
    getQrCodeClient() {
        return fetch(`${this.host}/client-qrcode`,
            {
                method: 'GET',
                credentials: 'include'
            }
        )
    }

    //пробросить данные из инпутов и отправить на сервер для авторизации пользователя
    login(dataUser) {
        return fetch(`${this.host}/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                password: dataUser.password,
                email: dataUser.email,
            })
        }).then((res) => { return res.json() })
            .catch((err) => console.log(err))
    };

    logout() {
        return fetch(`${this.host}/signout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        }).then((res) => { return res.json() })
            .catch((err) => console.log(err))
    }

    //запрос на сервер для авторизации
    getToken(tkn) {
        return fetch(`${this.host}/users/me`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tkn}`,
            },
            credentials: 'include',
        }).then((res) => res.json())
            .catch((err) => console.log(err))
    }

    // запрос данных пользователя (вся инфа: Имя, Телефон, Абонементы, Посещения)
    getUserInfo(idClient) {
        return fetch(`${this.host}/dark/client-info/${idClient}`,
            {
                method: 'GET',
                credentials: 'include'
            }
        ).then((res) => this._getResponse(res))
    }

    //АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------АБОНЕМЕНТЫ**---------

    // получить абонементы по клиенту
    getClientAbonement(data) {
        return fetch(`${this.host}/dark/control/abonements/${data}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // Получить архив абонементов по клиенту
    getHystoryAbonement(idClient) {
        return fetch(`${this.host}/dark/control/hystoryabonement/${idClient}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // создать абонемент 
    createAbonement(data, idClient) {
        return fetch(`${this.host}/dark/control/abonements`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                client: idClient, // id клиента
                dateOn: data.dateOn, // дата начала
                dateTo: data.dateTo, // дата до
                quantityVisitsOn: data.quantityVisitsOn, // количество тренировок в абонементе
                quantityVisits: data.quantityVisits, // остаток тренировок в абонементе
                type: data.type, // тип абонемента
                cost: data.cost, // стоимость
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //скорректировать абонемент
    updateAbonement(data, id) {
        return fetch(`${this.host}/dark/abonement/control/${id}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                dateOn: data.dateOn, // дата начала
                dateTo: data.dateTo, // дата до
                quantityVisits: data.quantityVisits, // количество тренировок в абонементе
                quantityVisitsOn: data.quantityVisitsOn,
                type: data.type, // тип абонемента
                cost: data.cost, // стоимость абонемента
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //Отправить заявку на заморозку абонемента
    sendFreezeAbonementfetch(formData) {
        return fetch(`${this.host}/dark/abonement-freeze`, {
            method: "POST",
            credentials: 'include',
            body: formData,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------ТРЕНИРОВКИ-------------
    // получить все тренировки
    getAllTrains() {
        return fetch(`${this.host}/dark/trains`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // получить конкретную тренировку по id
    getTrainById(id) {
        return fetch(`${this.host}/dark/control/train/${id}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    //получить тренировки для отрисовки календаря
    getTrainsForCalendar() {
        return fetch(`${this.host}/dark/trains-calendar`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // получить тренировки по датам
    getTrainsOfDate(date) {
        return fetch(`${this.host}/dark/trains-date/${date}`, {
            method: 'GET',
            credentials: 'include',
        }).then((res) => this._getResponse(res));
    }

    // получить тренировки, на которые записан клиент
    getTrainsOfClientsVisit(id) {
        return fetch(`${this.host}/dark/trains-client/${id}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // получить тренировки, на которые записан клиент на конкретную дату
    getTrainsOfClientsVisitDate(clientId, dateTrain) {
        return fetch(`${this.host}/dark/trains-client/${clientId}/${dateTrain}`, {
            method: 'GET',
            credentials: 'include'
        }).then((res) => this._getResponse(res));
    }

    // добавить клиента на тренировку(только по действующему абонементу)
    addVisitOnTrain(idTrain, idClient, typeOfTraining) {
        return fetch(`${this.host}/dark/add-visit`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient, // id из БД клиента сейчас захардкоден, вернуться на этапе авторизации
                idTrain: idTrain, // id из БД тренировки
                typeOfTraining: typeOfTraining, // тип тренировки
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    // добавить клиента на разовую групповую тренировку предварительно создав её(только админ, клиент сам не может, перс в отдельном методе ниже)
    addOneTimeGroupeVisit(
        idClient, // id клиента
        idTrain, // id тренировки
        typeOfTraining, // тип тренировки
    ) {
        return fetch(`${this.host}/dark/control/train/onetimegroupevisit`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient,
                idTrain: idTrain,
                typeOfTraining: typeOfTraining,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });

    }

    // отменить запись (клиент сам отменяет)
    removeVisitOnTrain(idTrain, idClient) {
        return fetch(`${this.host}/dark/remove-visit`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient,
                idTrain: idTrain, // id из БД тренировки
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    // отмена визита на тренировку(Доступ админа, тк можно отменить и после начала тренировки)
    removeVisitClientByAdmin(idTrain, idClient) {
        return fetch(`${this.host}/dark/control/removevisit`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient,
                idTrain: idTrain, // id из БД тренировки
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        })
    }

    //Разовые записи на тренировки

    //Создать разовую запись на тренировку 
    createListOneTimeTrain(idTrain, idClient) {
        return fetch(`${this.host}/dark/listonetime`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient,
                idTrain: idTrain,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //отменить разовую запись на тренировку
    removeListOneTimetraining(idList, idClient, idTrain, typeOfTraining) {
        return fetch(`${this.host}/dark/listonetimeremove`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idList: idList,
                idClient: idClient,
                idTrain: idTrain,
                typeOfTraining: typeOfTraining,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    //Включить уведомления
    enableNotifications(idClient, FMCToken) {
        return fetch(`${this.host}/client-notificationweb-enable`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient,
                FMCToken: FMCToken,
            })
        })
    }

    //Выключить уведомления
    turnoffNotifications(idClient) {
        return fetch(`${this.host}/client-notificatioweb-turnoff`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: idClient,
            })
        })
    }

    // Создать абонемент на разовую тренировку после потверждения присутствия на тренировке
    createAbonementForOneTimeTraining(idTrain, trainDateOn, idClient, abonementType, abonementCost, idList) {
        return fetch(`${this.host}/dark/onetimeaddabonement`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                trainDateOn: trainDateOn,
                idClient: idClient,
                idTrain: idTrain,
                abonementType,
                idList,
                costAbonement: abonementCost
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

}

const apiDark = new Api({
    host: 'https://api.darkflex.ru',
    //host: 'https://www.api.darkflex.ru',
    //host: 'http://localhost:3000',
});

export default apiDark;