//PageStudio -> this
//AboutClientPage -> this


import { useEffect, useState } from "react";

import previousWeekButtonImg from './../../images/previous-week.svg'; // картинка перекл недели
import nextMonthButtonImg from './../../images/next-week.svg'; // картинка перекл недели

import Preloader from "../Preloader/Preloader"; // лоадер ( пока загружается)
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import DayList from "../DayList/DayList";

function TimeTable({
    api, // передать АПИ студии из компонента студии в которое монтируется расписание
    textTitleColor, // цвет текста
    styleText, // стиль текста
    titleTimeTable, // заголовок таблицы
    timeTableBackgroundColor, // цвет фона таблицы
    loaderTimeTable, // лоадер пока приходят тренировки с АПИ ( true или false )
    timetableButtonStyle, // цвет фона кнопок переключения недели
    trains,// массив тренировок в расписание из компонента студии в которое монтируется расписание
    setTrains, // менять стейт массива тренировок в компоненте студии в которое монтируется расписание
    statePage, //какая страница открыта ( общая или личная) если true - добавить на тренировку, если false - убрать
    idClient,
    disabledButtonTrain, // работает ли клик на тренировку или нет, true - не работает, нужно для отображения компонента списка посещений
    timetableListTextStyle, // стиль для текста в истории посещений
    client, // проверка есть ли клиент в БД, если нет, то не показывать инф об абонементе (true - клиент есть в БД)
    isAdmin, // true - страница админа, false - клиента, используется для записи на разовые тренировки
    setIsChangeInfo, // мгновенно отрисовать изменения в абонементе на странице
    isTypeSpace, // показывать ли тип зала
    backgroundColorForm, // цвет формы
    handleAddVisitOnGroupeTraining, // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
    handleAddVisitOnPersonalTraining, // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
    handleRemoveVisitOnGroupeTraining, // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
    handleRemoveVisitOnPersonalTraining, // пробросить, чтобы отрисовать сразу изменение количества посещений в абонементе у клиента
}) {

    //Тренировки
    //const [trains, setTrains] = useState([]); // массив тренировок

    // Инфотул для работы с тренировкой 
    const [isInfoToolOpen, setIsInfoToolOpen] = useState(false); // стейт для открытия информационного окна
    const [isTextMassageInfoTool, setTextMassageInfoTool] = useState(''); // текс в инфотул
    const [isButtonInfoTool, setButtonInfoTool] = useState(false); // отображать или нет кнопки в инфотуле
    //Инфотул для связи с администратором
    const [isInfoToolToWriteOpen, setIsInfoToolToWriteOpen] = useState(false); // стейт для открытия информационного окна
    const [isTextMassageInfoToolToWrite, setTextMassageInfoToolToWrite] = useState(''); // текс в инфотул

    const [idTrain, setIdTrain] = useState(''); // айди тренировки для записи во время открытия Инфотула
    const [typeOfTraining, setTypeOfTraining] = useState('') // тип тренировки для добавления на нее клиента 
    const [dateTraining, setDateTraining] = useState('') // дата тренировки для отправки в WA не зареганному клиенту
    const [isChange, setIsChange] = useState(false) // мгновенная отрисовка изменений
    const [isChangeDayLoader, setIsChangeDayLoader] = useState(false) // отображать лоадер при смене дня недели пока грузится расписание

    // Работа с тренировками

    function openInfoToolGetVisit(idTrain, time, dateTrain, typeOfTraining) { // записать клиента на тренировку
        setButtonInfoTool(true); // отображать кнопки в инфотул
        setIsInfoToolOpen(true); // открыть инфотул
        setTextMassageInfoTool(`${statePage ? "Запись на тренировку в" : "Отменить запись в"} ${time}`); // передать текст в инфотул в зависимости от стейта
        setIdTrain(idTrain) // записать айди кликнутой тренировки для дальнейшей записи на нее
        let date = `${time} ${dateTrain}`
        setDateTraining(date) // проставить дату и время для передачи сообщения в WA
        setTypeOfTraining(typeOfTraining) // передать тип тренировки в АПИ
    }

    //если нет действующего абонемента
    function openInfoToolWithOutAbonement(idTrain, typeOfTraining) {
        setIsInfoToolToWriteOpen(true)
        setIdTrain(idTrain) // записать айди кликнутой тренировки для дальнейшей записи на нее
        setTypeOfTraining(typeOfTraining) // передать тип тренировки в АПИ
    }

    function handleClickMwssage() {
        const message = `Добрый день, прошу записать меня на тренировку в ${dateTraining}`; // Ваше заготовленное сообщение
        const phoneNumber = '+79995602900'; // Номер телефона получателя в формате international number

        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        window.open(whatsappURL);
    };

    //Добавить визит на тренировку
    function getVisit() { // добавить клиента на тренировку
        if (client) {
            // если клиент заведен в базу данных
            api.addVisitOnTrain(idTrain, idClient, typeOfTraining) // id захардкоден 
                .then(() => {
                    setIsInfoToolOpen(true);
                    setButtonInfoTool(false); // спрятать кнопки в инфотуле
                    setTextMassageInfoTool('Успешно')

                    //Найти тип тренировки для того, чтобы использовать необходимый метод отрисовки инф в абонементе
                    const training = trains.find((train) => train._id = idTrain)
                    if (training.typeOfTraining === 'групповая') {
                        handleAddVisitOnGroupeTraining(idTrain);
                    } else if (training.typeOfTraining.includes('персональная')) {
                        handleAddVisitOnPersonalTraining(idTrain);
                    }

                    if (trains !== undefined) {
                        trains.forEach((train) => {
                            if (train._id === idTrain) {
                                train.listOfVisits.push(idClient);
                                setTrains(trains);
                                setIsChange(true); //мгновенная отрисовка изменений в расписании
                            }
                        });
                    }
                    setTimeout(() => {
                        setIsInfoToolOpen(false);
                    }, 3000)
                })
                .catch((err) => {
                    if (err.status === 409) {
                        if (err.message === "Клиент с такими данными уже записан на тренировку") {
                            setButtonInfoTool(false) // не отображать кнопки в инфотул
                            setIsInfoToolOpen(true) // открыть инфотул
                            setTextMassageInfoTool("Вы уже записаны на тренировку, для отмены перейдите в личный кабинет")
                        } else if (err.message === "Превышена максимальная вместимость") {
                            setButtonInfoTool(false) // не отображать кнопки в инфотул
                            setIsInfoToolOpen(true) // открыть инфотул
                            setTextMassageInfoTool("Места закончились, мы уведомим вас, если место освободится")
                        } else if (err.message === "Необходимо продлить абонемент") {
                            setIsInfoToolOpen(false);
                            setIsInfoToolToWriteOpen(true);
                            setTextMassageInfoToolToWrite("Отсутствует действующий абонемент")
                        } else if (err.message === "Абонемент не найден") {
                            setIsInfoToolOpen(false);
                            setIsInfoToolToWriteOpen(true);
                            setTextMassageInfoToolToWrite("Отсутствует действующий абонемент")
                        } else {
                            setButtonInfoTool(false) // не отображать кнопки в инфотул
                            setIsInfoToolOpen(true) // открыть инфотул
                            setTextMassageInfoTool("Произошла ошибка")
                        }
                        setTimeout(() => {
                            setIsInfoToolOpen(false) // закрыть и очистить через 3 сек
                            setTextMassageInfoTool("")
                        }, 3000)
                    } else {
                        setButtonInfoTool(false) // не отображать кнопки в инфотул
                        setIsInfoToolOpen(true) // открыть инфотул
                        setTextMassageInfoTool("Произошла ошибка")
                        setTimeout(() => {
                            setIsInfoToolOpen(false) // закрыть и очистить через 3 сек
                            setTextMassageInfoTool("")
                        }, 3000)
                        console.log(err)
                    }
                })
        } else if (!client && !isAdmin) {
            setIsInfoToolToWriteOpen(true);
            setTextMassageInfoToolToWrite("Отсутсвует действующий абонемент")
        }
    }

    //Отменить запись на тренировку
    function removeVisit() {
        isAdmin ?
            api.removeVisitClientByAdmin(idTrain, idClient) // метод отмены для админа(может отменить после начала тренировки)
                .then(() => {
                    closeInfoTool();
                    setIsChange(true) // мгновенно отрисовать изменения на странице
                    if (trains !== undefined) {
                        const updateTrains = trains.map((train) => {
                            if (train._id === idTrain) {
                                train.listOfVisits.push(idClient);
                            }
                            return train; // добавляем return для возвращаемых элементов
                        })
                        setTrains(updateTrains); // обновить количество на тренировке
                    }

                })
            :
            api.removeVisitOnTrain(idTrain, idClient) // метод отмены для клиента
                .then(() => {
                    closeInfoTool();
                    setIsChange(true) // мгновенно отрисовать изменения на странице
                    if (trains !== undefined) {
                        const updateTrainingsArray = trains.filter(training => training._id !== idTrain)
                        setTrains(updateTrainingsArray);
                        //Найти тип тренировки для того, чтобы использовать необходимый метод отрисовки инф в абонементе
                        const training = trains.find((train) => train._id = idTrain)
                        if (training.typeOfTraining === 'групповая') {
                            handleRemoveVisitOnGroupeTraining(idTrain);
                        } else if (training.typeOfTraining.includes('персональная')) {
                            handleRemoveVisitOnPersonalTraining(idTrain);
                        }
                    }
                })
                .catch((err) => {
                    if (err.status === 409) {
                        setButtonInfoTool(false) // не отображать кнопки в инфотул
                        setIsInfoToolOpen(true) // открыть инфотул
                        if (err.message === "Клиент с такими данными не записан на тренировку") {
                            setTextMassageInfoTool("Клиент с такими данными не записан на тренировку")
                        } else if (err.message === "Отмена записи возможна только за 3 часа до начала тренировки") {
                            setTextMassageInfoTool("Отмена записи возможна только за 3 часа до начала тренировки")
                        } else {
                            setTextMassageInfoTool("Что-то пошло не так")
                        }
                        setTimeout(() => {
                            setIsInfoToolOpen(false) // закрыть и очистить через 3 сек
                            setTextMassageInfoTool("")
                        }, 3000)
                    } else {
                        setButtonInfoTool(false) // не отображать кнопки в инфотул
                        setIsInfoToolOpen(true) // открыть инфотул
                        setTextMassageInfoTool("Что-то пошло не так")
                        console.log(err)
                        setTimeout(() => {
                            setIsInfoToolOpen(false) // закрыть и очистить через 3 сек
                            setTextMassageInfoTool("")
                        }, 3000)
                    }
                });
    }

    // работа с Инфотулом
    function closeInfoTool() { // свернуть инфотул
        setIsInfoToolOpen(false);
        setTextMassageInfoTool('')
    }

    function handleCloseInfoTool(e) { // закрыть инфотул на оверлей или эск 
        if (e.key === "Escape" || e.target.classList.contains('info-tooltip__overlay')) {
            closeInfoTool(e);
        }
    }

    //Инфотул для записи на разовую либо продления абонемента
    function closeInfoToolToWrite() {
        setIsInfoToolToWriteOpen(false);
    }

    function writeToSignUpForATraining() { // написать администратору чтобы записаться на разовую
        const message = `Добрый день, прошу записать меня на разовую тренировку в ${dateTraining}`; // Ваше заготовленное сообщение
        const phoneNumber = '+79995655605'; // Номер телефона получателя в формате international number

        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        window.open(whatsappURL);
    }

    function writeToBuyAbonement() { // написать, чтобы купить абонемент
        const message = `Добрый день, прошу уточнить о покупке абонемента(первый/есть существующий)`; // Ваше заготовленное сообщение
        const phoneNumber = '+79995655605'; // Номер телефона получателя в формате international number

        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        window.open(whatsappURL);
    }

    // если админ, то при клике записи на разовую
    function signUpForAoneTimeTraining() { // записать на разовую
        api.addOneTimeGroupeVisit(
            idClient, // id клиента
            idTrain, // id тренировки
            typeOfTraining, // тип тренировки
        ).then(() => {
            setIsInfoToolToWriteOpen(false);
            setButtonInfoTool(false);
            setIsInfoToolOpen(true);
            setTextMassageInfoTool('Клиент добавлен на разовую тренировку');
            setIsChange(true); // для мгновенной отрисовки
            if (trains !== undefined) {
                const updateTrains = trains.map((train) => {
                    if (train._id === idTrain) {
                        train.listOfVisits.push(idClient);
                    }
                    return train; // добавляем return для возвращаемых элементов
                })
                setTrains(updateTrains); // обновить количество на тренировке
            }
        }).catch((err) => {
            setIsInfoToolToWriteOpen(false);
            setButtonInfoTool(false);
            setIsInfoToolOpen(true);
            setTextMassageInfoTool(`Ошибка: ${err.message}`)
        });
    }

    useEffect(() => { // слушатели на закрытие инфотул // свернуть на esc или клик на оверлей
        if (isInfoToolOpen) {
            document.addEventListener('click', handleCloseInfoTool);
            document.addEventListener('keydown', handleCloseInfoTool);
        } else {
            document.removeEventListener('click', handleCloseInfoTool);
            document.removeEventListener('keydown', handleCloseInfoTool);
        }
    }, [isInfoToolOpen])

    //Слушать измения и сообщать об этом в компонент выше для мгновенной отрисовки
    useEffect(() => {
        if (isChange && typeof setIsChangeInfo === 'function') {
            setIsChangeInfo();
        }
    }, [isChange, setIsChangeInfo]);

    //Слушать измения и сбрасывать стейт контроля изменений
    useEffect(() => {
        if (isChange) {
            setTimeout(() => { setIsChange(false) }, 1000)
        }
    }, [isChange]);

    function handleChangeDayLoader(state) {
        setIsChangeDayLoader(state);
    }

    return (
        <div className={`timetable ${timetableListTextStyle}`}>
            {/* ИНФОТУЛ ДЛЯ ЗАПИСИ ИЛИ ОТМЕНЫ ЗАПИСИ НА ТРЕНИРОВКУ */}
            {isInfoToolOpen ?
                <InfoTooltip
                    styleText={styleText}
                    isOpen={isInfoToolOpen}
                    text={isTextMassageInfoTool}
                    isClose={closeInfoTool}
                    showButton={isButtonInfoTool}
                    backgoundColor={backgroundColorForm}
                    textButtonOne={'Да'} // текст кнопки
                    textButtonTwo={'Нет'} // текст кнопки
                    clickButtonOne={() =>
                        statePage ? // если передан true , то страница общих тренировок
                            getVisit()
                            : // если false, то страница личных записей
                            removeVisit()}
                    clickButtonTwo={closeInfoTool}
                />
                : <></>}
            {/* ИНФОТУЛ ДЛЯ СВЯЗИ С АДМИНИСТРАТОРОМ ИЛИ ЗАПИСИ НА РАЗОВУЮ ГРУППУ(ЕСЛИ АДМИН) ЕСЛИ ЗАКОНЧИЛСЯ ИЛИ ОТСУТСВУЕТ АБОНЕМЕНТ */}
            {isInfoToolToWriteOpen ?
                <InfoTooltip
                    styleText={styleText}
                    isOpen={isInfoToolToWriteOpen}
                    text={isTextMassageInfoToolToWrite}
                    isClose={closeInfoToolToWrite}
                    showButton={true}
                    backgoundColor={backgroundColorForm}
                    textButtonOne={'Записать на разовую'} // текст кнопки
                    textButtonTwo={isAdmin ? 'Нет' : 'Новый абонемент'} // текст кнопки
                    clickButtonOne={() =>
                        isAdmin ? // если админ
                            signUpForAoneTimeTraining() // записать на разовую
                            : // если клиент
                            writeToSignUpForATraining() // отправить сообщение админу с просьбой записи
                    }
                    clickButtonTwo={() =>
                        isAdmin ?
                            () => { } // заглушка
                            :
                            writeToBuyAbonement()
                    }
                />
                : <></>}
            <h2 className={`timetable__title ${textTitleColor}`}>{titleTimeTable}</h2>
            {statePage ? // если показ активных записей, то не показывать дни недели
                <DayList
                    textTitleColor={textTitleColor} // цвет текста
                    titleTimeTable={titleTimeTable}
                    timetableButtonStyle={timetableButtonStyle}
                    previousWeekButtonImg={previousWeekButtonImg}
                    nextMonthButtonImg={nextMonthButtonImg}
                    api={api}
                    setTrains={setTrains} // изменить тренировки в зависимости от выбранного дня
                    statePage={statePage} // какая страница открыта 
                    idClient={idClient} // айди клиента 
                    handleChangeLoader={handleChangeDayLoader} // мгновенно отрисовать изменения в абонементе на странице
                    styleText={styleText} // стиль текста
                />
                : <></>}
            <div className="timetable__list">
                {loaderTimeTable || isChangeDayLoader ? // данные в процессе получения - отобразить лоадер
                    <Preloader /> :
                    trains !== undefined && trains.length > 0 ? // если массив тренировок не пустой
                        trains.map(train => {

                            let date = new Date(train.dateOn);
                            let dateTo = new Date(train.dateTo);

                            let dateTrain = new Intl.DateTimeFormat('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' }).format(date);
                            let formattedDate = dateTrain.replace(/\b(\d)\b/g, '0$1'); // Добавление ведущего нуля, если день месяца однозначный

                            let timeTo = dateTo.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
                            let time = date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });

                            //Данные для заполнения
                            let trainer; // тренер
                            if (train.trainer !== undefined && train.trainer !== null && train.trainer.name !== undefined && train.trainer.name !== null) {
                                trainer = train.trainer.name;
                            } else {
                                trainer = 'тренер';
                            }
                            //Тип тренировки
                            let typeTraining;
                            if (train.typeOfTraining.includes('групповая')) {
                                typeTraining = 'групповая'
                            } else if (train.typeOfTraining.includes('сплит')) {
                                typeTraining = 'сплит'
                            } else if (train.typeOfTraining.includes('персональная')) {
                                typeTraining = 'персональная'
                            } else {
                                typeTraining = train.typeOfTraining;
                            }

                            return <button
                                disabled={disabledButtonTrain}
                                className={`timetable__list-element ${timeTableBackgroundColor}`}
                                id={train._id}
                                key={train._id}
                                onClick={() => {
                                    openInfoToolGetVisit(train._id, time, formattedDate, train.typeOfTraining)
                                }}>

                                <div className="timetable__element" >
                                    <p className={`timetable__text ${styleText} `}>{formattedDate}</p>
                                    <p className={`timetable__text ${styleText} timetable__element_dateTrain`}>{train.name}</p>
                                </div>
                                <div className="timetable__element" >
                                    <p className={`timetable__text ${styleText}`}>{time} - {timeTo}</p>
                                    <p className={`timetable__text ${styleText}`}>{trainer}</p>
                                </div>
                                <div className="timetable__element" >
                                    {isTypeSpace ?  // показывать ли тип зала
                                        <p className={`timetable__text ${styleText}`}>{train.typeSpace} зал</p>
                                        : <></>}
                                    <p className={`timetable__text ${styleText}`}>Вместимость {train.capacity}</p>
                                    {/* <p className={`timetable__text ${styleText}`}>{typeTraining}</p> */}
                                </div>
                                {/* {isTypeSpace ?  // показывать ли тип зала
                                    <div className="timetable__element" >
                                        <p className={`timetable__text ${styleText}`}>{train.typeSpace} зал</p>
                                    </div>
                                    :
                                    <></>} */}
                            </button>
                        })

                        :
                        <p className={`timetable__text-no-record ${textTitleColor}`}>Записи отсутсвуют</p>

                }
            </div>
        </div >
    )
}




export default TimeTable;